html,
body {
  height: 100%;
  margin: 0;
  background-color: #dee2e633;
}

.hero-heading {
  text-align: center;
}

.margin {
  margin-top: 10px;
}

.wrapper {
  padding-top: 20px;
}

.aadhar-card {
  /* margin: 80px auto; */
  background-color: #fff;
  height: 250px;
  min-width: 35%;
  width: 35%;
  padding: 15px 0;
  box-shadow: rgb(107 79 104) 0px 2px 4px;
  border-radius: 5px;
  /* border: 3px solid #079ff44d; */
}

.header {
  padding: 0 25px;
  display: flex;
  /* justify-content: space-between; */
}

.emblem-div {
  /* height: 35px;
  width: 45px; */
}

.emblem {
  height: inherit;
  width: inherit;
}

.tri-color {
  width: 100%;
}

.orange {
  background-color: #808080;
  height: 10px;
  width: 60%;
}

.white {
  background-color: #fff;
  height: 10px;
}

.green {
  background-color: #6f6f6f;
  height: 10px;
  width: 75%;
}

.middle {
  padding: 0 10px;
  margin-top: 35px;
  display: flex;
}

.user-avatar {
  width: 80px;
  height: 75px;
  /* border: 2px solid #4157f7; */
  border-radius: 10px;
  box-shadow: 2px 1px 3px 0px #00000052;
}

.user-image {
  width: inherit;
  height: inherit;
}

.user-details {
  width: 100%;
  margin-left: 20px;
}

.name {
  background-color: #808080ba;
  width: 30%;
  height: 10px;
}

.session {
  margin-top: 8px;
  width: 50%;
  background-color: #808080ba;
  height: 10px;
}
.dob {
  margin-top: 8px;
  width: 30%;
  background-color: #808080ba;
  height: 10px;
}

.aadhar-input {
  margin-top: 35px;
  width: 80%;
}

.enter-aadhar {
  width: 100%;
}

.aadhar-input-border {
  border: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

input:focus {
  outline: none;
}

.red-line {
  width: 100%;
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.line {
  background-color: red;
  height: 3px;
}

.aadhar-line {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}

.aadhar-hindi {
  width: 20%;
  height: 10px;
  background-color: #808080ba;
  margin-right: 4px;
}

.aam-admi {
  width: 40%;
  height: 10px;
  background-color: #808080ba;
}

.submit {
  margin-top: 40px;
  text-align: center;
}

.submit-button {
  color: #fff;
  width: 250px;
}

.arrow-back {
  width: 20%;
  float: left;
  cursor: pointer;
}

.otp-heading {
  width: 80%;
}

.otp-header {
  width: 100%;
  margin-bottom: 70px;
}

.otp-image-div {
  width: 100px;
  height: 90px;
  margin: auto;
}

.image-otp {
  height: inherit;
  width: inherit;
}

.input-div {
  margin-top: 30px;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 3px;
  text-align: center;
  border-radius: 4px;
  background-color: #ccc;
  border: 1px solid #ccc;
}

.otp-buttons {
  width: 100%;
  display: flex;
  margin-top: 40px;
  text-align: center;
  justify-content: center;
}

.otp-button {
  width: 160px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (min-width: 250px) and (max-width: 767px) {
  /* For mobile phones: */
  .aadhar-card {
    width: 95%;
    /* margin-inline: 0 10px; */
  }
  .green {
    width: 67%;
  }
  .wrapper {
    padding: 20px 20px 0;
  }
  .submit-button {
    color: #fff;
    width: 50%;
  }

  .otp-button {
    width: 150px;
  }

  .otpInput {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* For mobile phones: */
  .aadhar-card {
    width: 45%;
  }
}

.table {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  padding-left: 0px !important;
  justify-content: center;
}

.languageName {
  margin: 5px;
}
